import CardsEnum from '../enumerators/cards.enum'

export default class CardsComponent {
    constructor() {
        CardsComponent.a11yLink()
    }

    static a11yLink() {
        if ($(CardsEnum.TRIGGER_A11Y_LINK).length > 0) {
            let ctrl = 0

            $(window).on('keydown', (e) => {
                const url = $(e.target).attr('data-a11y-link')

                if (url) {
                    // Press ctrl
                    if (e.which === 17 || e.which === 91) {
                        ctrl = true
                    }

                    // Press enter
                    if (e.which === 13) {
                        if (ctrl) {
                            window.open(url, '_blank')
                        } else {
                            window.location = url
                        }
                    }
                }
            })
        }
    }
}
