/**
 *
 */
export default class EcoTriggerComponent {
    /**
     *
     */
    constructor() {
        EcoTriggerComponent.initialize()
    }

    static initialize() {
        if ($('[data-trigger-eco-block]').length > 0) {
            $('[data-eco-block]').each(function () {
                $(this).bind('eco-block-loaded', function () {
                    if (!$(this).hasClass('wp-block--eco-loaded')) {
                        EcoTriggerComponent.loadedState($(this))
                    }
                })
            })

            $(document).on('click', '[data-trigger-eco-block]', function () {
                const block = $(this).closest('[data-eco-block]')
                EcoTriggerComponent.loadingState(block)
            })
        }
    }

    static loadingState(element) {
        element.addClass('wp-block--eco-loading')
    }

    static loadedState(element) {
        element.toggleClass('wp-block--eco-loading wp-block--eco-loaded')
    }
}
