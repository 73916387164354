export default class PreloadService {
    constructor() {
        PreloadService.init()
    }

    static init() {
        // Intersection Observer config
        const observer = new IntersectionObserver(
            (entries) => {
                if (window.scrollY === 0) {
                    for (const entry of entries) {
                        if (entry.isIntersecting && PreloadService.isElementVisible(entry.target)) {
                            PreloadService.processImage(entry.target)
                            observer.unobserve(entry.target)
                        }
                    }
                }
            },
            {
                rootMargin: '0px',
                threshold: 0,
            },
        )

        // Get all images and observe them
        for (const image of document.querySelectorAll('img')) {
            observer.observe(image)
        }
    }

    static processImage(image) {
        if (image) {
            // Remove lazy loading if exist (handcook)
            const dataSrc = image.getAttribute('data-hc-src')
            if (dataSrc) {
                image.removeAttribute('data-hc-src')
                image.src = dataSrc
            }

            // Remove lazy loading if exist (native)
            if (image.src && image.loading === 'lazy') {
                image.removeAttribute('loading')
            }

            // Add preload
            const link = document.createElement('link')
            link.rel = 'preload'
            link.href = image.src
            link.as = 'image'
            link.setAttribute('fetchpriority', 'high')
            image.setAttribute('fetchpriority', 'high')
            document.head.appendChild(link)
        }
    }

    static isElementVisible(element) {
        // Check if element is visible
        const style = window.getComputedStyle(element)
        const isVisible =
            style.display !== 'none' && style.visibility !== 'hidden' && style.opacity !== '0'

        // Check if element have height or width and is in viewport
        const rect = element.getBoundingClientRect()
        const hasScope =
            rect.top !== rect.bottom &&
            rect.left !== rect.right &&
            (rect.top > 0 ||
                rect.left > 0 ||
                rect.right < window.innerWidth ||
                rect.bottom < window.innerHeight)

        return isVisible && hasScope
    }
}
