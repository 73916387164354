import BottomBarEnum from '../enumerators/bottom-bar.enum'

export default class BottomBarService {
    constructor() {
        if ($(BottomBarEnum.BOTTOMBAR).length > 0) {
            import(
                /* webpackChunkName: "scripts/BottomBarComponent" */
                /* webpackMode: "weak" */
                '../components/bottom-bar.component'
            ).then(({ default: BottomBarComponent }) => {
                new BottomBarComponent()
            })
        }
    }
}
