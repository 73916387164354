import 'jquery'

import CardsComponent from './components/cards.component'
import ContentHelpfulYouComponent from './components/content-helpful-you.component'
import EcoBarComponent from './components/eco-bar.component'
import EcoTriggerComponent from './components/eco-trigger.component'
import FormComponent from './components/form.component'
import IrisHoverComponent from './components/iris-hover.component'
import LightboxComponent from './components/lightbox.component'
import NatsComponent from './components/nats.component'
import SelectComponent from './components/select.component'
import BottomBarService from './services/bottom-bar.service'
import DataLayerService from './services/data-layer.service'
import DropdownService from './services/dropdown.service'
import FiltersService from './services/filters.service'
import HookService from './services/hook.service'
import InitService from './services/init.service'
import LayoutService from './services/layout.service'
import LazyloadService from './services/lazyload.service'
import MapService from './services/map.service'
import ModalService from './services/modal.service'
import ObfuscationService from './services/obfuscation.service'
import OverlayService from './services/overlay.service'
import PreloadService from './services/preload.service'
import ShareService from './services/share.service'
import TabService from './services/tab.service'
import ToggleService from './services/toggle.service'
import TooltipService from './services/tooltip.service'

new InitService()
document.addEventListener('DOMContentLoaded', () => {
    new ContentHelpfulYouComponent()
    new IrisHoverComponent()
    new EcoBarComponent()
    new EcoTriggerComponent()
    new LightboxComponent()
    new NatsComponent()
    new ObfuscationService()
    new PreloadService() // Need to be before lazyloading service
    new LazyloadService()
    new MapService()
    new ModalService()
    new ToggleService()
    new DropdownService()
    new FiltersService()
    new ShareService()
    new TooltipService()
    new TabService()
    new SelectComponent()
    new FormComponent()
    new DataLayerService()
    new OverlayService()
    new HookService()
    new BottomBarService()
    new CardsComponent()
    new LayoutService()

    if (!IRISCollectionTheme.config.ecomodeActive) {
        import(
            /* webpackChunkName: "scripts/core-ui" */
            /* webpackMode: "weak" */
            './_core-ui'
        )
            .then(({ default: initUIScripts }) => {
                initUIScripts()
            })
            .catch((err) => {
                alert(err)
            })
    }
})
