export default class DataLayerService {
    constructor() {
        if (IRISCollectionTheme.config.GTMActive) {
            import(
                /* webpackChunkName: "scripts/data-layer" */
                /* webpackMode: "weak" */
                '../actions/data-layer.action'
            ).then(({ default: DataLayerAction }) => {
                new DataLayerAction()
            })
        }
    }
}
