export default class FiltersService {
    constructor() {
        if ($('[data-trigger="filter"]').length > 0 || $('[data-element="filters"]').length > 0) {
            import(
                /* webpackChunkName: "scripts/FiltersComponent" */
                /* webpackMode: "weak" */
                '@scripts/components/filters.component'
            ).then(({ default: FiltersComponent }) => {
                FiltersComponent()
            })
        }
    }
}
