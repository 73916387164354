export default class OverlayService {
    trigger = '[data-hc-overlay]'

    constructor() {
        const overlay = document.querySelectorAll(this.trigger)
        if (overlay.length > 0) {
            import(
                /* webpackChunkName: "scripts/hc_overlay" */
                /* webpackMode: "weak" */
                '@iris.interactive/handcook/public/scripts/components/overlay/overlay.component'
            ).then(({ default: hc_overlay }) => {
                hc_overlay()
            })
        }
    }
}
