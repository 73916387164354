import wrapSelect from '@iris.interactive/handcook/public/scripts/support/wrap-select.support'

export default class SelectComponent {
    constructor() {
        SelectComponent.select()
    }

    static select() {
        wrapSelect()
    }
}
