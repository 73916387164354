export default class NatsComponent {
    constructor() {
        NatsComponent.initComponent()
    }

    static initComponent() {
        import(
            /* webpackChunkName: "scripts/hc_nats" */
            /* webpackMode: "weak" */
            '@iris.interactive/handcook/public/scripts/components/nats/nats.component'
        ).then(({ default: hc_nats }) => {
            const hcNats = hc_nats()
            const postTitle = IRISCollectionTheme?.queriedObject?.post_title ?? null

            // Consultation d'une fiche détail SIT
            if ($('body.single-wpet').length > 0) {
                if (postTitle) {
                    hcNats.publishMessage('view-v2', postTitle)
                }
            }

            // Consultation d'une page avec une liste SIT
            if ($('[data-wpet-list-sit]').length > 0) {
                if (postTitle) {
                    hcNats.publishMessage('view-list-v2', postTitle)
                }
            }

            // Consultation d'un article de blog
            if ($('body.single-post').length > 0) {
                if (postTitle) {
                    hcNats.publishMessage('view-post-v2', postTitle)
                }
            }

            // Ajout element en favoris
            $('.iris-travel-book__button-add').click(function () {
                if ($(this).hasClass('isInTravelBook') === false && postTitle) {
                    hcNats.publishMessage('add-favourites-v2', postTitle)
                }
            })

            // Clique CTA fiche détail - Bloc Communication
            $('.wpet-communication a').click(() => {
                if (postTitle) {
                    hcNats.publishMessage('click-cta-sit-v2', postTitle)
                }
            })
            // Clique CTA fiche détail - Bloc Lien/Bouton
            $('.wp-block-wp-etourisme-v3-link a').click(() => {
                if (postTitle) {
                    hcNats.publishMessage('click-cta-sit-v2', postTitle)
                }
            })
            // Clique CTA fiche détail - Bloc Réservation
            $('.wp-block-wp-etourisme-v3-booking-button a').click(() => {
                if (postTitle) {
                    hcNats.publishMessage('click-cta-sit-v2', postTitle)
                }
            })

            // Réservation d'une offre SIT
            $('.GtmButtonConsultationResaPrestataire').click(() => {
                if (postTitle) {
                    hcNats.publishMessage('click-resa-v2', postTitle)
                }
            })

            // Consultation de la météo
            if ($('.wp-block-weather').length > 0) {
                if (postTitle) {
                    hcNats.publishMessage('view-weather-v2', postTitle)
                }
            }

            // Consultation des webcams
            if ($('.wp-block-webcam').length > 0) {
                if (postTitle) {
                    hcNats.publishMessage('view-webcam-v2', postTitle)
                }
            }

            // Consultation de l'info neige
            if ($('.wp-block-info-neige-station').length > 0) {
                if (postTitle) {
                    hcNats.publishMessage('view-neige-v2', postTitle)
                }
            }

            // Recherche sur le site
            $('.wp-search button').click(() => {
                if (postTitle) {
                    hcNats.publishMessage('click-search-v2', postTitle)
                }
            })

            // Consultation d'une brochure
            if ($('body.single-brochure').length > 0) {
                if (postTitle) {
                    hcNats.publishMessage('view-brochure-v2', postTitle)
                }
            }

            // Envoi d'un formulaire
            $('.frm_final_submit').click(() => {
                if (postTitle) {
                    hcNats.publishMessage('click-form-v2', postTitle)
                }
            })
        })
    }
}
