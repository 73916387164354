// biome-ignore lint/complexity/noStaticOnlyClass: <explanation>
export default class ObfuscationAction {
    static initObfuscation() {
        const element = '[data-obf]'
        if ($(element).length > 0) {
            // Add accessibility attributes
            $(element).each(function () {
                $(this).attr('role', 'link')
                $(this).attr('tabindex', '0')
                $(this).attr('aria-label', $(this).text())
            })
            const isTouchDevice = window.matchMedia('(pointer: coarse)').matches
            if (!isTouchDevice) {
                let ctrl = 0
                $(window).on('keydown', (e) => {
                    // Press ctrl
                    if (e.which === 17 || e.which === 91) {
                        ctrl = true
                    }

                    // Click on link with enter
                    if (e.which === 13) {
                        let blank = false
                        const dataObf = $(e.target).attr('data-obf')

                        if (dataObf) {
                            const url = atob(dataObf)
                            const target = $(e.target).attr('data-obf-target')

                            if (target === '_blank') {
                                blank = true
                            }

                            if (blank || ctrl) {
                                window.open(url, '_blank')
                            } else {
                                window.location = url
                            }
                        }
                    }
                })
                $(window).on('keyup', () => {
                    ctrl = false
                })

                $(document).on('mouseup', element, function (e) {
                    const url = atob($(this).attr('data-obf'))
                    const target = $(this).attr('data-obf-target')
                    if (e.which === 1 || e.which === 2) {
                        let blank = false
                        if (e.which === 2) {
                            blank = true
                        } // Clic molette
                        if (target === '_blank') {
                            blank = true
                        }
                        if (blank || ctrl) {
                            window.open(url, '_blank')
                        } else {
                            window.location = url
                        }
                        return false
                    }

                    return false
                })
            } else {
                $(document).on('click', element, function (e) {
                    let blank = false
                    const url = atob($(this).attr('data-obf'))
                    const target = $(this).attr('data-obf-target')
                    if (target === '_blank') {
                        blank = true
                    }
                    if (blank) {
                        window.open(url, '_blank')
                    } else {
                        window.location = url
                    }
                    return false
                })
            }
        }
    }
}
