export default class MapService {
    constructor() {
        if ($('[data-element="map"]').length > 0) {
            import(
                /* webpackChunkName: "scripts/MapComponent" */
                /* webpackMode: "weak" */
                '@scripts/components/map.component'
            ).then(({ default: MapComponent }) => {
                MapComponent.initializeMap()
            })
        }
    }
}
